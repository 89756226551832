<template>
    <div>
        <rxNavBar :title="title"></rxNavBar>
        <iframe style="height: 700px; width: 10rem; display: block" v-if="type=='ssqian'"
                :src="electronicContractUrl" frameborder=0 name="showHere" scrolling=auto
        ></iframe>
        <div v-for="(item,index) in numPages" :key="index" v-if="type=='fxqian'">
          <pdf
            :src="electronicContractUrl"
            ref="pdf"
            :page="index + 1"
          ></pdf>
        </div>
        <div class="bottom-fixed">
		</div>
    </div>

</template>

<script>
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import pdf from 'vue-pdf'

	import {
		Image as VanImage,
		Button,
	} from "vant";
    export default {
        components: {
            rxNavBar,
            [Button.name]: Button,
            pdf,
        },
        name: "electronicContract",
        data() {
            return {
                title:'',
                electronicContractUrl: '',
                numPages: '',
                type:'',
            }
        },
      created() {

      },
      mounted() {
          this.title = this.$route.query.title
          this.electronicContractUrl = this.$route.query.electronicContractUrl
        this.type = this.$route.query.type
          if(this.$route.query.type=='fxqian'){  //pdf
            this.getNumPages()
          }
        },
        methods:{
          getNumPages() {
            let loadingTask = pdf.createLoadingTask(this.electronicContractUrl)
            loadingTask.promise.then(pdf => {
              this.numPages = pdf.numPages
            }).catch(err => {
              console.error('pdf 加载失败', err);
            })
          },

    }
    }

</script>

<style lang="less" scoped>
	@redius: 8px;
	//.bottom-fixed {
	//	position: fixed;
	//	border-top: 1px solid rgb(243, 243, 243);
	//	width: 100%;
	//	height: 1.86667rem;
	//	bottom: 0px;
	//	background-color: white;
	//}
    .bottom_btn {
			// margin-left: 20px;
			/*padding:10px 15px;*/
			/*margin-left: 4.25rem;*/
			// margin-left: 3rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: end;

			.btn {
				width: 120px;
				margin-right: 10px;
				// margin-top: 10px;
				border-radius: @redius;
				background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
				background: -webkit-linear-gradient(left,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Firefox 3.6 - 15 */
				background: linear-gradient(to right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				color: rgb(255, 255, 255);
				font-size: 16px;
			}

			.btn_left {
				width: 120px;
				// margin-top: 10px;
				margin-right: 10px;
				border-radius: @redius;
				background: linear-gradient(rgb(124, 198, 255), rgb(54, 147, 255));
				background: -webkit-linear-gradient(left,
					rgb(124, 198, 255),
					rgb(54, 147, 255));
				/* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(right,
					rgb(124, 198, 255),
					rgb(54, 147, 255));
				/* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(right,
					rgb(124, 198, 255),
					rgb(54, 147, 255));
				/* Firefox 3.6 - 15 */
				background: linear-gradient(to right,
					rgb(124, 198, 255),
					rgb(54, 147, 255));
				color: rgb(255, 255, 255);
				font-size: 16px;
			}
		}
</style>